@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap');

body {
  font-family: 'Lexend', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*navigation bar */
.nav-link {
  position: relative;
  display: inline-block;
  padding-bottom: 4px;
  color: #4A4A4A;
  text-decoration: none;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #C5A467; /* Adjust to your gold color */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-link:hover {
  color: #1F1F1F; /* Adjust hover color if needed */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header */
.group:hover .group-hover\:block {
  display: block;
}

.relative:hover .group-hover\:block {
  display: block;
}

